import React from 'react';

import { Form } from '../form';
import { GameSearch } from './GameSearch';

const Example = () => {
  return (
    <Form defaultValues={{}} onSubmit={() => {}}>
      <GameSearch id="Example__game" name="game" title="Game" />
    </Form>
  );
};

export default Example;
